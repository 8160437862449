/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import list from './list'

const paylocity = (callAPI: Function) => {
    const url = '/paylocity'
    const urlPersonalEmail = `${url}/external`
    const urlBusinessEmail = `${url}/internal`

    return {
        list: () => list(callAPI, url),
        findByPersonalEmail: (email: string) =>
            list(callAPI, `${urlPersonalEmail}/${email}`),
        findByBusinessEmail: (email: string) =>
            list(callAPI, `${urlBusinessEmail}/${email}`)
    }
}

export default paylocity
