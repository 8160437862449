import { Account } from '../../../Interfaces'

const init = (account: Account) => ({
    method: 'POST',
    headers: {
        'Content-Type': 'application/json',
        'X-PINGOTHER': 'pingpong'
    },
    mode: 'cors',
    body: JSON.stringify(account)
})

const init2 = (body: any) => ({
    method: 'PUT',
    headers: {
        'Content-Type': 'application/json',
        'X-PINGOTHER': 'pingpong'
    },
    mode: 'cors',
    body: JSON.stringify(body)
})

const post = async (callAPI: any, url: string, account: Account) => {
    return await callAPI(
        url,
        init({ ...account, ip: localStorage.getItem('IP_USER') || '' })
    )
}

export const put = async (
    callAPI: any,
    url: string,
    validationBody: {
        group_id: string
        status?: string
        status_name?: string
        type_user?: string
    }
) => {
    return await callAPI(url, init2({ ...validationBody }))
}

export default post
