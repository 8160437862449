import React, { lazy } from 'react'
import { Suspense } from 'react'
import { Switch, BrowserRouter, Route } from 'react-router-dom'
import * as ROUTES from './routerList'
import { Provider } from 'react-redux'
import store from '../../core/redux/store'
import TermPrivacy from 'src/app/screens/TermPrivacy'
const SignIn = lazy(() => import('../../app/screens/SignIn/SignIn'))
const SignUp = lazy(() => import('../../app/screens/SignUp/SignUp'))

const SignRoutes: React.FC = () => {
    return (
        <BrowserRouter>
            <Provider store={store}>
                <Suspense fallback={''}>
                    <Switch>
                        <Route
                            path={ROUTES.RECOVER_PASSWORD}
                            component={SignIn}
                            exact
                        />
                        <Route
                            path={ROUTES.SIGN_UP}
                            component={() => <SignUp />}
                            exact
                        />
                        <Route
                            path={ROUTES.TERMPRIVACYPOLICY}
                            component={TermPrivacy}
                            exact
                        />
                        <Route
                            path={ROUTES.SIGN_IN}
                            component={() => <SignIn />}
                            exact
                        />
                        <Route
                            path={ROUTES.DEFAULT}
                            component={() => <SignIn />}
                            exact
                        />
                    </Switch>
                </Suspense>
            </Provider>
        </BrowserRouter>
    )
}

export default SignRoutes
