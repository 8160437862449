export const ipUser = async () => {
    if (localStorage.getItem('IP_USER')) {
        return
    }

    const ipify = await fetch(
        'https://api.ipify.org/?format=json'
    ).then((response) => response.json())
    localStorage.setItem('IP_USER', JSON.stringify(ipify.ip))
}
