import React, { useEffect, useState } from 'react'
import jwt from 'jsonwebtoken'
import { useCookies } from 'react-cookie'
import { useLocation } from 'react-router-dom'

import SignRoutes from './signRoutes'

const Routes: React.FC = () => {
    const [token, setToken] = useState<any>(null)
    const [cookies, setCookie, removeCookie] = useCookies(['token'])
    const location = useLocation()

    useEffect(() => {
        if (location.pathname === '/logout') {
            removeCookie('token', {
                domain: process.env.REACT_APP_DOMAIN
            })
        } else {
            jwt.verify(
                cookies.token,
                '8Zz5tw0Ionm3XPZZfN0NOml3z9FMfmpgXwovR9fp6ryDIoGRM8EPHAB6iHsc0fb',
                (err: any, decoded: any) => {
                    if (err) {
                        removeCookie('token', {
                            domain: process.env.REACT_APP_DOMAIN
                        })
                    } else {
                        if (Date.now() < decoded.exp * 1000) {
                            setToken(decoded)
                        }
                    }
                }
            )
        }
    }, [])

    if (token) {
        window.location.href =
            process.env.REACT_APP_URL || 'https://systemfeg.com'
    }
    return <SignRoutes />
}

export default Routes
