import React from 'react'
import ReactDOM from 'react-dom'
import './index.scss'
import { BrowserRouter } from 'react-router-dom'
import * as serviceWorker from './serviceWorker'
import * as setupImage from './setup-image'
import * as infoUser from './info-user'
import Routes from './config/routers/index'
import * as serviceLocation from './serviceLocation'
//const Routes = React.lazy(() => import('./config/routers/index'))

ReactDOM.render(
    <BrowserRouter>
        <Routes />
    </BrowserRouter>,
    document.getElementById('root')
)

// Setting lazy loading, all imagens static of system
serviceLocation.getLocation()
setupImage.loading()
infoUser.ipUser()

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
