const INITIAL_STATE = {
    active: false,
    playground: ''
}

const lcoation = (state = [], action: any) => {
    console.log(action, 'reducers-auth')

    switch (action.type) {
        case 'CREATE':
            const { playground } = action
            state = playground
            break
    }
    return state
}

export default lcoation
