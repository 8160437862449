import accounts from './accounts'
import locations from './locations'
import publics from './public-info'
import paylocity from './paylocity'

const API = (() => {
    const callAPI = async (endpoint: string, init?: RequestInit) => {
        try {
            return fetch(getUrl(endpoint), init).then((response) =>
                response.json()
            )
        } catch ({ response }) {
            // notifyError(response, url)
            console.log('error', response)
        }
    }

    const getUrl = (endpoint: string) => {
        return `${process.env.REACT_APP_API_URL}/api/v1${endpoint}`
    }

    return {
        accounts: accounts(callAPI),
        locations: locations(callAPI),
        publics: publics(callAPI),
        paylocity: paylocity(callAPI)
    }
})()

export default API
