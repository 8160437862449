import list from './list'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
// eslint-disable-next-line @typescript-eslint/ban-types
const publics = (callAPI: Function) => {
    const url = '/publics/privacy'

    return {
        getPrivacyPoliticTerm: () => list(callAPI, url)
    }
}

export default publics
