/* eslint-disable @typescript-eslint/no-var-requires */
import * as lazyLoadingImage from './lazy-loading-image'
interface Image {
    name: string
    url: string
}

const IMAGENS: Image[] = [
    //{ name: 'bg-login-left', url: 'https://i.ibb.co/8gB90h7/bg.png' },
    {
        name: 'bg-login-left',
        url:
            'https://s3.us-east-2.amazonaws.com/systemfeg.com/image/static/bg-min.webp'
    },
    //{ name: 'bg-login-right', url: 'https://i.ibb.co/8gB90h7/bg.png' },
    {
        name: 'bg-login-right',
        url:
            'https://s3.us-east-2.amazonaws.com/systemfeg.com/image/static/bg-account-min.webp'
    },
    {
        name: 'feghome-logo',
        url:
            'https://s3.us-east-2.amazonaws.com/systemfeg.com/image/static/feghome.com.webp'
    },
    {
        name: 'logo-simple',
        url:
            'https://s3.us-east-2.amazonaws.com/systemfeg.com/image/static/logo.webp'
    },
    {
        name: 'logo-full',
        url:
            'https://s3.us-east-2.amazonaws.com/systemfeg.com/image/static/FEG-logo.webp'
    }
]

const setImage = (image: Image): void => {
    localStorage.setItem(
        image.name,
        lazyLoadingImage.useProgressiveImage(image.url)
    )
}

export const loading = (): void => {
    //if (!localStorage.getItem('log-full')) {
    IMAGENS.map((image) => setImage(image))
    //}
}
