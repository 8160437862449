const INITIAL_STATE = {
    active: false,
    user: {}
}

const auth = (state = INITIAL_STATE, action: any) => {
    console.log(action, 'reducers-auth')

    switch (action.type) {
        case 'FREE_ACCESS':
            const { auth } = action
            if (auth.status === 'failure') {
                state.active = false
                break
            }
            state.user = auth
            state.active = true
            break
    }
    return state
}

export default auth
