import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
    width: 100%;
    background-image: url('https://i.ibb.co/8gB90h7/bg.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
`
const SubContainer = styled.div`
    width: 90%;
    margin: 42px auto;
    background-color: #ffd;
    padding: 20px;
    border-radius: 9px;
`

export default function TermPrivacy(): React.ReactElement {
    return (
        <Container>
            <SubContainer>
                <h2>EMPLOYEE PRIVACY POLICY - FEG Home</h2>
                <h2>EMPLOYEE PRIVACY POLICY</h2>
                <p>
                    <i>Last updated March 17, 2021</i>
                </p>
                <p>
                    Thank you for choosing to be part of our community at Family
                    Entertainment Group, LLC, and its affiliated companies
                    (referred to as the “Company”, “we”, “us” or “our”). We are
                    committed to protecting your personal information and your
                    right to privacy.
                </p>
                <p>
                    When you visit or use our websites, including without
                    limitation www.feghome.com, third-party software, mobile
                    apps, or otherwise engage with us through a computer or
                    mobile device (collectively, {`${'"Sites"'}`}), we
                    appreciate that you are trusting us with your personal
                    information.
                </p>
                <p>
                    This Privacy Policy describes our privacy practices
                    regarding the collection, use and sharing of personal
                    information relating to prospective, current, and former
                    employees of the Company.
                </p>
                <p>
                    Please take the time to read this Privacy Policy, as it is
                    important and will help you understand what we do with the
                    information that we collect. If you have any questions or
                    concerns about this Privacy Policy or our practices with
                    respect to your personal information, please discontinue
                    using our Sites and contact us at HR@fegllc.com.
                </p>
                <p>
                    <strong>
                        INFORMATION WE COLLECT AND HOW WE COLLECT IT
                    </strong>
                </p>
                <p>
                    We collect personal and other information about you.
                    Personal information is data that identifies, describes, or
                    could reasonably be linked with you. We collect personal
                    information about you in several ways and from several
                    sources.
                </p>
                <p>
                    We collect personal information directly from you,
                    automatically when you visit a Site and from third parties.
                    We also create information about you in the context of a
                    prospective or current employment relationship. The
                    following explains the personal information under each of
                    these categories of sources.
                </p>

                <p>
                    <strong>Information collected directly from you:</strong>
                </p>
                <p>
                    We, and our service providers, collect personal information
                    that you provide voluntarily as part of the hiring process
                    and your employment relationship. Personal information may
                    include personal identifiers such as names, mailing
                    addresses, email addresses, phone number, other contact
                    information, date of birth, Social Security number,
                    {`driver'`}s license number, passport number and similar
                    information.
                </p>
                <p>
                    Personal information may also include personal
                    characteristics such as age, race, ancestry, national
                    origin, citizenship, religion, marital status, disability,
                    sex, pregnancy or childbirth and related medical conditions,
                    sexual orientation and veteran or military status, and
                    similar information. This includes personal information
                    provided in resumes and employment applications, information
                    collected in connection with the onboarding process and as
                    part of your employment relationships including information
                    for payroll purposes such as your bank information for
                    direct deposits.
                </p>
                <p>
                    information for direct deposits. We also collect personal
                    information provided in connection with subscribing to
                    health and welfare benefits, and personal information about
                    your beneficiaries and emergency contact information. Our
                    Sites are not intended for children. We do not knowingly
                    collect any information from children under the age of
                    sixteen.
                </p>
                <p>
                    <strong>Information collected automatically:</strong>
                </p>
                <p>
                    When you visit our Sites, we and our service providers
                    automatically collect personal and other information about
                    your use of and access to these Sites. Like many other
                    businesses, we collect this information through a variety of
                    tracking technologies, including cookies. Information
                    collected in this manner may include device and usage
                    information, such as your IP address, internet service
                    provider, browser and device characteristics, operating
                    system, location, information regarding how and when you use
                    our Sites, and other technical information. It may also
                    include analytics information collected by us or via third
                    party analytics tools, to help us measure traffic and usage
                    trends for the Sites and to understand more about the
                    demographics and behaviors of our users.
                </p>
                <p>
                    If you prefer not to accept cookies, most browsers will
                    allow you to change your browser settings to disable
                    existing cookies, automatically request cookies or to notify
                    you when you receive a cookie. However, if you disable,
                    modify, or reject cookies, some parts or functionalities of
                    the Sites may be inaccessible or not function properly.
                </p>
                <ul>
                    <li>
                        <strong>
                            Information collected from third parties:{' '}
                        </strong>
                        <br />
                        <p>
                            We collect personal information about you from third
                            parties such as consumer reporting agencies, job
                            websites, application providers, and data providers
                            for purposes of employment (prospective or current),
                            to maintain applicable benefits and for other
                            purposes stated below. We also collect personal
                            information from third parties such as social
                            networking sites or other application providers who
                            have your consent to share the personal information
                            or otherwise have the right to share the personal
                            information with us.
                        </p>
                    </li>
                </ul>
                <p>
                    <strong>Information created about you:</strong>
                </p>
                <p>
                    We collect or create information about you based on our
                    interaction with you as a prospective or current employee
                    such as interview notes, performance information, and wage
                    and benefit information.
                </p>
                <p>
                    <strong>USE OF YOUR INFORMATION</strong>
                </p>
                <p>
                    We use personal information collected from or about you
                    (including personal information about your dependents,
                    approved family members, beneficiaries and emergency
                    contacts) for business purposes including to:
                </p>
                <ul>
                    <li>
                        Process your application for employment, request a
                        background check, submit a screening assessment, assess
                        qualifications for a job, and otherwise determine
                        eligibility for employment.
                    </li>
                    <li>
                        Identify, review and manage candidacy for job
                        opportunities.
                    </li>
                    <li>
                        Provide or administer wages, payments, and benefits.
                    </li>
                    <li>
                        Conduct performance reviews, establish training
                        requirements, and conduct investigations or pursue
                        disciplinary actions or termination.
                    </li>
                    <li>
                        Compile directories and establish contacts in the event
                        of an emergency.
                    </li>
                    <li>
                        Comply with legal requirements, assist in an
                        investigation, comply with requests from regliatory and
                        law enforcement authorities and meet contractual
                        obligations.
                    </li>
                    <li>
                        Communicate with you about information relevant to
                        employment.
                    </li>
                    <li>
                        Provide you with employment related tools, systems or
                        applications including mobile applications for voluntary
                        wellness programs.
                    </li>
                    <li>
                        Protect the safety and security of our business,
                        services, and Sites including to detect and prevent
                        suspected fraud, threats to our network or other illegal
                        activities, prevent misuse or for any other reason
                        permitted by law.
                    </li>
                    <li>
                        Update or correct our records including combining
                        information we have with information received from other
                        sources, including publicly-available databases.
                    </li>
                    <li>
                        Remember information so that you will not have to
                        re-enter it during your visit or the next time you visit
                        the Sites; Analyze how our applications and Sites are
                        used, Provide and monitor the effectiveness of our
                        Sites.
                    </li>
                    <li>
                        Perform analytics and detect usage patterns on our
                        Sites.
                    </li>
                    <li>Diagnose or fix technology problems.</li>
                    <li>Otherwise to plan for and enhance our Sites.</li>
                    <li>
                        Carry out other activities required in the normal course
                        of our employment relationship with you.
                    </li>
                </ul>
                <p>
                    <strong>FEG Calendar:</strong>
                </p>
                <p>
                    FEG Calendar is a feature offered as a corporate calendar
                    with events related to the {`company's`} interest. Events
                    created on your FEG Home calendar, including personal
                    events, can be accessed at any time by one of the
                    {` system's`}
                    master users/super administrator.
                </p>
                <p>
                    <strong>Tracking Use Of Partner Software:</strong>
                </p>
                <p>
                    To optimize browsing culture, data consumption and
                    understand the usage of our platforms, we can collect
                    information about how you are accessing partner platforms,
                    including: Microsoft Power BI, FEG ERP
                    (http://system.fegllc.com/), Zendesk Guide, Zendesk Support,
                    Zendesk Gather, Paylocity, Netsuite, Schoox, Voya Financial,
                    Blue Cross Blue Shield and any other platform used within
                    the Family Entertainment {`Group's`} environment of
                    interest.
                </p>
                <p>
                    <strong>SHARING OF YOUR INFORMATION</strong>
                </p>
                <p>
                    We do not sell your personal information. We may share your
                    personal information with our affiliates for business
                    purposes consistent with the uses described in this Privacy
                    Policy. We may also share information about you with third
                    parties whenever you consent to or direct such sharing. We
                    strive to work with companies that share our commitment to
                    privacy. We may also share information with others in an
                    aggregated or de-identified form that does not reasonably
                    identify you.
                </p>
                <p>
                    We may also share your personal or other information with
                    third parties for business purposes or as required or
                    permitted by law including with:
                </p>
                <ul>
                    <li>
                        Service providers: Personal information may be shared
                        with service
                    </li>
                    <li>
                        Third parties in connection with a business transaction:
                        Personal information may be disclosed to third parties
                        in connection with a corporate transaction, such as a
                        merger, sale of any or all of our company assets or
                        shares, reorganization, financing, change of control or
                        acquisition of all or a portion of our business.
                    </li>
                    <li>
                        Law enforcement, regulators and other parties for legal
                        reasons: Personal information may be disclosed to third
                        parties, as required by law or subpoena, or if we
                        reasonably believe such action is necessary to: comply
                        with the law and the reasonable requests of regulators,
                        court orders, law enforcement or other public
                        authorities, such as a subpoena, government audit or
                        search warrant; comply with a contract or as necessary
                        to establish, exercise or defend against potential,
                        threatened or actual litigation; protect us, your vital
                        interests, or those of another person; and investigate
                        fraud or to protect the security or integrity of our
                        Sites or any product or services.
                    </li>
                </ul>

                <p>
                    <strong>Mobile Terms and Conditions</strong>
                </p>

                <p>
                    You agree to receive automated informational and
                    communication text messages from Family Entertainment Group.
                    These messages relate to your account, location, or group
                    and may be sent using an automatic telephone dialing system
                    to the mobile telephone number you provided when signing up.
                    These messages are not for promotional or marketing
                    purposes. Message frequency varies. Message and Data rates
                    may apply.{' '}
                </p>

                <p>
                    To opt-out of the text message program, text the keyword
                    {'"STOP"'}. You will receive one additional message
                    confirming that your request has been processed
                    successfully. For more information, text the keyword{' '}
                    {'"HELP"'} for our customer care details. You can also
                    contact us at support@fegllc.zendesk.com or 469-288-3915 for
                    further assistance with your inquiry.
                </p>
                <p>
                    <strong>
                        ACCESSING, MODIFYING AND DELETING YOUR INFORMATION
                    </strong>
                </p>
                <p>
                    It is important that the information contained in our
                    records is both accurate and current. If your personal
                    information happens to change, please keep us informed of
                    such changes and update information on your profile at
                    www.feghome.com. Prospective employees may verify or correct
                    personal information via their candidate and/or applicant
                    profile. Former employees may verify or correct personal
                    information by contacting us at HR@fegllc.com.
                </p>
                <p>
                    <strong>THIRD-PARTY SITES</strong>
                </p>
                <p>
                    Links to third-party websites may be provided on our Sites
                    as a convenience to you. If you choose to go to these
                    external websites, you will be subject to the privacy
                    practices of those external websites. We are not responsible
                    for the privacy practices of those websites. We encourage
                    you to be aware when you leave our website to read the
                    privacy policies or statements of every third party’s
                    website you visit, as those privacy policies or statements
                    may differ from ours. This Privacy Policy applies only to
                    our Sites.
                </p>
                <p>
                    <strong>SECURITY</strong>
                </p>
                <p>
                    The security of your personal information is important to
                    us. We use a combination of reasonable technical,
                    administrative, and physical safeguards to protect your
                    personal information. However, no website, mobile
                    application, database or system is completely secure or
                    “hacker-proof.” So, we cannot guarantee its absolute
                    security. You are also responsible for taking reasonable
                    steps to protect your personal information against
                    unauthorized disclosure or misuse and to follow and
                    implement all policies and practices to protect personal
                    information collected by us. We limit access to your
                    personal information to those who need it to do their jobs.
                    We comply with all applicable federal and state data
                    security laws.
                </p>
                <p>
                    <strong>CHANGES TO OUR PRIVACY POLICY</strong>
                </p>
                <p>
                    We may periodically update or revise this Privacy Policy.
                    The date at the top of this page shows when this Privacy
                    Policy was last revised. We will let you know when we update
                    the Privacy Policy by changing the date or other appropriate
                    means.
                </p>
                <p>
                    <strong>CONTACT US</strong>
                </p>
                <p>
                    If you have questions or concerns about our privacy
                    practices, please contact us at HR@fegllc.com.
                </p>
            </SubContainer>
        </Container>
    )
}
