import { list } from './list'
import post, { put } from './post'
import { Account } from '../../../Interfaces'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
// eslint-disable-next-line @typescript-eslint/ban-types
const accounts: any = (callAPI: Function) => {
    const url = '/accounts'
    const urlAuth = `${url}/auth`
    const urlRecover = `${url}/recover`
    const urlUpdatePassword = `${url}/change/password`
    const urlByEmail = `${url}/byEmail`
    const urlhaveEmail = `${url}/haveEmail`
    const urlSSOLogin = `${url}/sso/login`
    const urlSSOAccounts = `${url}/sso/users`

    return {
        list: () => list(callAPI, url),
        ssoLogin: () => list(callAPI, urlSSOLogin),
        ssoAccounts: () => list(callAPI, urlSSOAccounts),
        haveEmail: (email: string) => list(callAPI, `${urlhaveEmail}/${email}`),
        post: (account: Account) => post(callAPI, url, account),
        auth: (account: Account) => post(callAPI, urlAuth, account),
        recover: (email: Account) => post(callAPI, urlRecover, email),
        updatePassword: (account: any) =>
            post(callAPI, urlUpdatePassword, account),
        validation: (id: string, group_id: string, type_user: string) => {
            put(callAPI, `${url}/${id}`, { group_id, status: 'ACTIVE' })
            put(callAPI, `${url}/change_status/${id}`, {
                group_id,
                status_name: 'active',
                type_user
            })
        },
        getId: (email: string) => list(callAPI, `${urlByEmail}/${email}`)
    }
}

export default accounts
